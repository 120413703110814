/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"
import store from "@/store/store";

export default {
  addItem({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.post("/api/data-list/products/", {
          item: item
        })
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, {
            id: response.data.id
          }))
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListItems({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get(store.state.apiURL + "/api/productitem", store.state.auth.apiHeader)
        .then((response) => {
          commit('SET_PRODUCTS', response.data)
          console.log(store.state.product.products);
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // fetchEventLabels({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("/api/apps/calendar/labels")
  //       .then((response) => {
  //         commit('SET_LABELS', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  updateItem({
    commit
  }, item) {
    return new Promise((resolve, reject) => {
      axios.put(store.state.apiURL + "/api/productitem/" + item, item, store.state.auth.apiHeader)
        .then((response) => {
          commit('UPDATE_PRODUCT', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeItem({
    commit
  }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(store.state.apiURL + "/api/productitem/" + itemId, store.state.auth.apiHeader)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

}
